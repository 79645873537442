import React from "react";
import useBranchData from "../../useBranchData";

const Contacts = () => {
    const {
        branchData: {
            contact: { email, phone, address },
        },
    } = useBranchData();
    return (
        <div className='contacts'>
            <div className='contacts-items'>
                <div className='contacts-item'>
                    <div className='contacts-item-description'>
                        <p>
                            <a href={`mailto:${email}`}>{email}</a>
                        </p>
                    </div>

                    <div className='contacts-item-title'>
                        <h6>Email</h6>
                    </div>
                </div>

                <div className='contacts-item'>
                    <div className='contacts-item-description'>
                        <p>{address}</p>
                    </div>

                    <div className='contacts-item-title'>
                        <h6>Adresa</h6>
                    </div>
                </div>

                <div className='contacts-item'>
                    <div className='contacts-item-description'>
                        <p>
                            <a href={`tel:${phone.replace(/\s/g, "")}`}>
                                {phone}
                            </a>
                        </p>
                    </div>

                    <div className='contacts-item-title'>
                        <h6>Telefon</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
