import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleContacts from "../blocks/page-title/PageTitleContacts";
// import ContactForm from "../components/form/ContactForm";

import GoogleMaps from "../blocks/google-maps/GoogleMaps";
import ContactsInside from "../blocks/contacts/Contacts";
import useBranchData from "../useBranchData";

const Contacts = () => {
    document.body.classList.add("page");
    const {
        branch,
        branchData: {
            map: { lat, lng },
        },
    } = useBranchData();

    return (
        <Fragment>
            <MetaTags>
                <meta charSet='UTF-8' />
                <title>Kontakt | The Crown Barbershop</title>

                <meta httpEquiv='x-ua-compatible' content='ie=edge' />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1'
                />
                <meta name='description' content='' />
                <meta name='keywords' content='' />
                <meta name='robots' content='index, follow, noodp' />
                <meta name='googlebot' content='index, follow' />
                <meta name='google' content='notranslate' />
                <meta name='format-detection' content='telephone=no' />
            </MetaTags>

            <Loading />

            <Header logoColor='light' isLandingPage={!branch} isBgDark />

            <main id='main' className='site-main'>
                <PageTitleContacts />
                <div id='page-content' className='spacer p-top-xl'>
                    <div className='wrapper'>
                        <div className='content'>
                            <div className='clearfix'>
                                {branch && <GoogleMaps lat={lat} lng={lng} />}

                                <div className='spacer p-top-xl'>
                                    <div className='row gutter-width-sm with-pb-xl spacer p-top-lg'>
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                            <div className='title pb-50'>
                                                <h2 className='text-uppercase'>
                                                    Napište nám
                                                </h2>
                                            </div>
                                            <ContactsInside />
                                        </div>

                                        {branch && (
                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                                <div className='title pb-50'>
                                                    <h2 className='text-uppercase'>
                                                        Pohled na holičství z
                                                        ulice
                                                    </h2>
                                                </div>
                                                <div className='contact-image'>
                                                    <img
                                                        src={`../assets/img/${branch}/outside.jpeg`}
                                                        alt='Pobočka zvenčí'
                                                    />
                                                </div>

                                                {/* <div className='contact-form-shortcode'>
                                                <ContactForm />
                                            </div> */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
