import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import { BRANCHES, DETAILS } from "../data/branches/items";
import { Link } from "react-router-dom";

const Landing = () => {
    document.body.classList.add("home");
    document.body.classList.add("header-absolute-true");

    return (
        <Fragment>
            <MetaTags>
                <meta charSet='UTF-8' />
                <title>The Crown Barbershop</title>

                <meta httpEquiv='x-ua-compatible' content='ie=edge' />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1'
                />
                <meta name='description' content='' />
                <meta name='keywords' content='' />
                <meta name='robots' content='index, follow, noodp' />
                <meta name='googlebot' content='index, follow' />
                <meta name='google' content='notranslate' />
                <meta name='format-detection' content='telephone=no' />
            </MetaTags>

            <Loading />

            <Header logoColor='light' isLandingPage />
            <img
                src='../assets/img/placeholder/logo-black.png'
                className='landing-img'
                alt='The Crown Barbers'
            />

            <main id='main' className='site-main content-no-spacing'>
                <div className='content'>
                    <div className='clearfix'>
                        <section className='block spacer pb-100'>
                            <div className='wrapper'>
                                <div className='content'>
                                    <div className='col-xl-12 col-lg-12 col-md-12 single-content'>
                                        <h2 className='text-center pb-100'>
                                            VYBERTE SI SVOU POBOČKU
                                        </h2>
                                        <div className='row gutter-width-sm with-pb-lg'>
                                            {BRANCHES.map(({ id, title }) => {
                                                const branchData = DETAILS[id];
                                                return (
                                                    <div
                                                        key={id}
                                                        className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12'
                                                    >
                                                        <div className='card card-post pb-100'>
                                                            <div className='card-top position-relative'>
                                                                <Link
                                                                    to={
                                                                        "/" + id
                                                                    }
                                                                >
                                                                    <div className='img object-fit overflow-hidden'>
                                                                        <div className='object-fit-cover transform-scale-h'>
                                                                            <img
                                                                                className='card-top-img'
                                                                                src={
                                                                                    branchData
                                                                                        .aboutUs
                                                                                        .image
                                                                                }
                                                                                alt={
                                                                                    "Obrazek pobocky"
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                            <div className='card-body'>
                                                                <div className='card-meta'>
                                                                    <p>
                                                                        <span>
                                                                            <a
                                                                                className='btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space text-uppercase'
                                                                                href={
                                                                                    "/" +
                                                                                    id
                                                                                }
                                                                            >
                                                                                {
                                                                                    "Prohlédnout pobočku"
                                                                                }
                                                                            </a>
                                                                        </span>
                                                                    </p>
                                                                </div>

                                                                <h4 className='card-title'>
                                                                    <a
                                                                        title={
                                                                            "item.title"
                                                                        }
                                                                        href={
                                                                            "/" +
                                                                            id
                                                                        }
                                                                    >
                                                                        {title}
                                                                    </a>
                                                                </h4>

                                                                <div className='full-flex'>
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                branchData
                                                                                    .contact
                                                                                    .address
                                                                            }
                                                                            <br />
                                                                            {
                                                                                branchData
                                                                                    .contact
                                                                                    .phone
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            Po -
                                                                            Pá:{" "}
                                                                            {
                                                                                branchData
                                                                                    .contact
                                                                                    .openingHoursWeek
                                                                            }
                                                                            <br />
                                                                            So:{" "}
                                                                            {
                                                                                branchData
                                                                                    .contact
                                                                                    .openingHoursSaturday
                                                                            }
                                                                            <br />
                                                                            {branchData
                                                                                .contact
                                                                                .openingHoursSunday && (
                                                                                <>
                                                                                    Ne:{" "}
                                                                                    {
                                                                                        branchData
                                                                                            .contact
                                                                                            .openingHoursSunday
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <a
                                                                            className='reservation-box'
                                                                            href={
                                                                                branchData.reservationLink ||
                                                                                ""
                                                                            }
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                        >
                                                                            <span>
                                                                                Rezervovat
                                                                                teď
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                    {/* {
                                                                        branchData
                                                                            .aboutUs
                                                                            .description
                                                                    } */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <ContactsUs contactUsLink={"/contacts"} /> */}

                        {/* <div id='img' className='block spacer p-top-xl'>
                            <div className='img-no-wrap-1 img-gray'>
                                <div className='img object-fit'>
                                    <div className='object-fit-cover'>
                                        <img
                                            src='assets/img/placeholder/1920x700.jpg'
                                            alt='Beauty Salon'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <News />

                        <Newsletter /> */}
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Landing;
