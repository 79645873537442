import React from "react";
import { Link, useParams } from "react-router-dom";
import { BRANCHES } from "../../data/branches/items";

const BranchModalMenu = ({ closeModal }) => {
    const { branch: branchID } = useParams();
    return (
        <nav className='menu-primary'>
            <ul className='nav'>
                {BRANCHES.map((branch, key) => (
                    <li
                        key={key}
                        className={
                            "nav-item" +
                            //change to param or recoil value
                            (branchID === branch.id ? " current-nav-item" : "")
                        }
                        onClick={closeModal}
                    >
                        <Link to={"/" + branch.id}>{branch.title}</Link>
                        {/* <a
                            title={branch.title}
                            href={process.env.PUBLIC_URL + "/" + branch.id}
                        >
                            {branch.title}
                        </a> */}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default BranchModalMenu;
