import React from "react";
import { useParams } from "react-router-dom";
import { DETAILS } from "../../data/branches/items";

const Actions = () => {
    const { branch } = useParams();
    return (
        <div className='actions'>
            <div className='actions-items'>
                {/* <a className='actions-item' href={reservationLink}> */}
                <a
                    className='actions-item'
                    href={DETAILS[branch]?.reservationLink || ""}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <span>Rezervovat termín</span>
                </a>
            </div>
        </div>
    );
};

export default Actions;
