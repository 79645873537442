import React from "react";
import useBranchData from "../../useBranchData";

const OpeningHours = () => {
    const {
        branchData: {
            contact: {
                openingHoursWeek,
                openingHoursSaturday,
                openingHoursSunday,
            },
        },
    } = useBranchData();

    return (
        <section id='opening-hours' className='block spacer p-top-xl'>
            <div className='bg-white spacer p-top-xl p-bottom-xl footer-no-border'>
                <div className='wrapper'>
                    <div className='newsletter'>
                        <div className='newsletter-title'>
                            <h2>Otevírací doba</h2>
                        </div>

                        <div className='newsletter-description'>
                            <p>
                                Po - Pá: {openingHoursWeek}
                                <br />
                                So: {openingHoursSaturday}
                                <br />
                                Ne: {openingHoursSunday}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OpeningHours;
