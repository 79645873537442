import React from "react";

const Copyright = () => {
    return (
        <div className='copyright'>
            <p>
                © {new Date().getFullYear()} The Crown Barber Shop /{" "}
                <a
                    className='secondary-semibold'
                    href={process.env.PUBLIC_URL + "/contacts"}
                >
                    KONTAKT
                </a>
            </p>
        </div>
    );
};

export default Copyright;
