import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import News from "./pages/News";
import NewsSinglePost from "./pages/NewsSinglePost";
import Gallery from "./pages/Gallery";
import SearchResults from "./pages/SearchResults";
import Services from "./pages/Services";
import ServicesInside from "./pages/ServicesInside";
import page404 from "./pages/404";
import UserInterface from "./pages/UserInterface";
import Landing from "./pages/Landing";
import Pricing from "./pages/Pricing";

function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/"}`}
                    component={Landing}
                />
                <Route
                    path={`${process.env.PUBLIC_URL + "/contacts"}`}
                    component={Contacts}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/:branch/"}`}
                    component={Home}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/:branch/about-us"}`}
                    component={About}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/:branch/contacts"}`}
                    component={Contacts}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/:branch/news"}`}
                    component={News}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/:branch/gallery"}`}
                    component={Gallery}
                />
                <Route
                    exact
                    path={`${
                        process.env.PUBLIC_URL + "/:branch/news-single-post"
                    }`}
                    component={NewsSinglePost}
                />
                <Route
                    exact
                    path={`${
                        process.env.PUBLIC_URL + "/:branch/search-results"
                    }`}
                    component={SearchResults}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/:branch/services"}`}
                    component={Services}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/:branch/ui"}`}
                    component={UserInterface}
                />
                <Route
                    exact
                    path={`${
                        process.env.PUBLIC_URL + "/:branch/services-inside"
                    }`}
                    component={ServicesInside}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/:branch/pricing"}`}
                    component={Pricing}
                />
                <Route exact component={page404} />
            </Switch>
        </Router>
    );
}

export default App;
