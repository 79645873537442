export const BRANCHES = [
    {
        title: "Holešovice",
        id: "holesovice",
    },
    {
        title: "Dejvice",
        id: "dejvice",
    },
    {
        title: "Kladno",
        id: "kladno",
    },
    {
        title: "Kralupy",
        id: "kralupy",
    },
    {
        title: "Hostivice",
        id: "hostivice",
    },
];

export const DefaultBranch = {
    hero: {
        image: "assets/img/placeholder/logo-black.jpg",
        title: "Neznámá pobočka",
        titlePrevious: "",
        description: "",
    },
    aboutUs: {
        description: "",
        smallDescription: "",
        team: [
            {
                photo: "",
                name: "",
                description: "",
            },
        ],
    },
    services: [],
    pricing: {
        description: "",
        items: [
            {
                service: "",
                price: 0,
            },
        ],
    },
    reservationLink: "",
    contact: {
        email: "",
        phone: "",
        address: "",
    },
    team: [
        {
            photo: "",
            name: "",
            description: "",
            position: "",
        },
    ],
    socials: {
        facebook: "",
        instagram: "",
    },
    map: {
        lat: 0,
        lng: 0,
    },
};

export const DETAILS = {
    holesovice: {
        hero: {
            imageSecondary: "assets/img/placeholder/branch-placeholder.jpeg",
            title: "Holešovice",
            titlePrevious: "Underground Barbershop",
            description:
                "Svěřte své vlasy a vousy do rukou pravých profesionálů. V The Crown Barber Shop se naši barbeři postarají o to, abyste vždy odcházeli zrelaxování a hlavně spokojení. Přijďte se k nám posadit do křesla, o zbytek se postaráme my.",
        },
        aboutUs: {
            image: "../assets/img/holesovice/hero.jpeg",
            description: "",
            smallDescription:
                "Placení je u nás možné pouze hotově. V našem barbeshopu si můžete zakoupit dárkové poukazy.",
            team: [
                {
                    photo: "../assets/img/holesovice/barberi-michal.png",
                    name: "Michal",
                    description:
                        "Michalova kariérní cesta byla poměrně přímá. Hned po vyučení v oboru kadeřník zamířil do praxe. A protože ho práce s břitvou lákala více, než dámské trvalé, pánský barbershop byl rozhodně to pravé. Po několika letech sbírání zkušeností jako zaměstnanec se rozhodl si v roce 2020 otevřít vlastní barbershop. Vítejte v Undergroundu.",
                },
                {
                    photo: "../assets/img/holesovice/barberi-honza.png",
                    name: "Honza",
                    description:
                        "Honza je další zkušený barber v našem týmu. Po několika letech sbírání zkušeností se dokáže skvěle postarat o vaše vlasy i vousy.",
                },
                {
                    photo: "../assets/img/holesovice/barberi-boldys.png",
                    name: "Boldys",
                    description:
                        "Michala u nás všichni znají spíše jako Boldyse. S praxí od roku 2018, kadeřnickou školou a následnou průpravou v barbershopu se rozhodně neztratí.",
                },
                {
                    photo: "../assets/img/holesovice/barberi-ludek.png",
                    name: "Luděk",
                    description:
                        "Mám zkušenosti se stříháním 8 let. Rád dělám kompletní proměny a nejvíc mě baví hair tattoo.",
                },
                {
                    photo: "../assets/img/holesovice/barberi-lukas.png",
                    name: "Lukáš",
                    description:
                        "Mám zkušenost se stříháním 6let. Stříhání jsem dříve měl jako koníček, teď je to mou prací. Nejraději dělám klasické střihy.",
                },
                {
                    photo: "../assets/img/holesovice/barberi-tomas.png",
                    name: "Tomáš",
                    description:
                        "Stříháním vlasů a vousů se zabývám 4 roky. Moje práce mě na 100% baví a dělám ji na 100%. Nejlepší pocit je, když můj zákazník odchází upravený a spokojený.",
                },
            ],
        },
        services: [
            {
                id: "1",
                title: "Střih vlasů / Hair cut",
                price: "Od 390 Kč",
                link: "/pricing",
                image: "../assets/img/placeholder/haircut.jpeg",
            },
            {
                id: "2",
                title: "Úprava vousů / Beard trimming",
                price: "Od 550 Kč",
                link: "/pricing",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "3",
                title: "Střih vlasů a úprava vousů / Hair cut and beard trimming",
                price: "Od 950 Kč",
                link: "/pricing",
                image: "../assets/img/placeholder/haircut_and_shave.jpeg",
            },
            {
                id: "4",
                title: "Barvení vousů / Beard coloring",
                price: "Od 440 Kč",
                link: "/pricing",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "5",
                title: "Střih vlasů a barvení vousů / Hair cut and beard coloring",
                price: "Od 890 Kč",
                link: "/pricing",
                image: "../assets/img/placeholder/haircut.jpeg",
            },
            {
                id: "6",
                title: "Úprava vousů a barvení / Beard trimming and coloring",
                price: "Od 890 Kč",
                link: "/pricing",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "7",
                title: "Střih vlasů, úprava a barvení vousů",
                price: "Od 1390 Kč",
                link: "/pricing",
                image: "../assets/img/placeholder/haircut_and_shave.jpeg",
            },
        ],
        pricing: {
            description: "",
            image: "",
            items: [
                {
                    id: "1",
                    title: "",
                    items: [
                        {
                            title: "Střih vlasů / Hair cut",
                            price: "690 Kč",
                        },
                        {
                            title: "Střih strojkem / Shaver cut",
                            price: "490 Kč",
                        },
                        {
                            title: "Dětský střih / Children's cut",
                            price: "390 Kč",
                        },
                    ],
                },
                {
                    id: "2",
                    title: "",
                    items: [
                        {
                            title: "Holení do hladka / Shave smooth",
                            price: "550 Kč",
                        },
                        {
                            title: "Úprava vousů / Beard trimming",
                            price: "550 Kč",
                        },
                        {
                            title: "Barvení vousů / Beard coloring",
                            price: "440 Kč",
                        },
                    ],
                },
                {
                    id: "3",
                    title: "",
                    items: [
                        {
                            title: "Klasický střih + úprava vousů / holení",
                            price: "1190 Kč",
                        },
                        {
                            title: "Střih strojkem + úprava vousů / holení",
                            price: "990 Kč",
                        },
                    ],
                },
                {
                    id: "4",
                    title: "",
                    items: [
                        {
                            title: "Klasický střih + úprava vousů + barvení vousů",
                            price: "1590 Kč",
                        },
                    ],
                },
            ],
        },
        reservationLink:
            "https://services.bookio.com/underground-barbershop/widget?lang=cs",
        contact: {
            email: "thecrownbarbers@gmail.com",
            phone: "+420 702 100 790",
            address: "Tusarova 791/31,Dělnická 32 ,170 00, Praha",
            openingHoursWeek: "9:00 - 20:00",
            openingHoursSaturday: "9:00 - 17:00",
            openingHoursSunday: "9:00 - 17:00",
        },
        team: [
            {
                photo: "",
                name: "",
                description: "",
                position: "",
            },
        ],
        socials: {
            facebook: "",
            instagram: "",
        },
        map: {
            lat: 50.10323021048523,
            lng: 14.449433300000003,
        },
    },
    dejvice: {
        hero: {
            imageSecondary: "assets/img/dejvice/hero.jpeg",
            title: "Dejvice",
            titlePrevious: "Barbershop Dejvice",
            description:
                "Svěřte své vlasy a vousy do rukou pravých profesionálů. V The Crown Barber Shop se naši barbeři postarají o to, abyste vždy odcházeli zrelaxování a hlavně spokojení. Přijďte se k nám posadit do křesla, o zbytek se postaráme my.",
        },
        aboutUs: {
            image: "../assets/img/dejvice/hero.jpeg",
            description: "",
            smallDescription: "",
            team: [
                {
                    photo: "../assets/img/dejvice/tomas.jpeg",
                    name: "Tomáš",
                },
                {
                    photo: "../assets/img/dejvice/bara.jpeg",
                    name: "Bára",
                },
            ],
        },
        services: [
            {
                id: "1",
                title: "Klasický střih",
                link: "/pricing",
                image: "../assets/img/placeholder/haircut.jpeg",
                description: "Klasický střih nůžkami nebo strojkem.",
            },
            {
                id: "2",
                title: "Úprava vousů",
                link: "/pricing",
                image: "../assets/img/placeholder/shave.jpeg",
                description: "Holení do hladka nebo úprava vousů břitvou.",
            },
        ],
        pricing: {
            description: "Ceník",
            image: "../assets/img/dejvice/pricing.jpeg",
            items: [
                {
                    id: "1",
                    title: "",
                    items: [
                        {
                            title: "Klasický střih",
                            price: "640 Kč",
                        },
                        {
                            title: "Střih strojkem",
                            price: "490 Kč",
                        },
                        {
                            title: "Dětský střih",
                            price: "390 Kč",
                        },
                        {
                            title: "Úprava vousů",
                            price: "550 Kč",
                        },
                        {
                            title: "Klasický střih + úprava vousů",
                            price: "1090 Kč",
                        },
                        {
                            title: "Střih strojkem + úprava vousů",
                            price: "950 Kč",
                        },
                    ],
                },
            ],
        },
        reservationLink:
            "https://services.bookio.com/barbershop-dejvice/widget?lang=cs",
        contact: {
            email: "thecrownbarbers@gmail.com",
            phone: "+420 606 853 015",
            address: "Muchova 237/15 Praha 6 - Dejvice, 160 00",
            openingHoursWeek: "10:00 - 20:00",
            openingHoursSaturday: "9:00 - 15:00",
        },
        team: [
            {
                photo: "",
                name: "",
                description: "",
                position: "",
            },
        ],
        socials: {
            facebook: "",
            instagram: "",
        },
        map: {
            lat: 50.09857636997702,
            lng: 14.405633115341674,
        },
    },
    kralupy: {
        hero: {
            imageSecondary: "assets/img/placeholder/branch-placeholder.jpeg",
            title: "Kralupy",
            titlePrevious: "Barbershop Kralupy",
            description:
                "Svěřte své vlasy a vousy do rukou pravých profesionálů. V The Crown Barber Shop se naši barbeři postarají o to, abyste vždy odcházeli zrelaxování a hlavně spokojení. Přijďte se k nám posadit do křesla, o zbytek se postaráme my.",
        },
        aboutUs: {
            image: "../assets/img/kralupy/hero.jpeg",
            description: "",
            smallDescription: "",
            team: [
                {
                    photo: "../assets/img/holesovice/barberi-radim.png",
                    name: "Radim",
                    description: "",
                },
                {
                    photo: "../assets/img/holesovice/barberi-boldys.png",
                    name: "Boldys",
                    description: "",
                },
                {
                    photo: "../assets/img/kralupy/martin.png",
                    name: "Martin",
                    description: "",
                },
            ],
        },
        services: [
            {
                id: "1",
                title: "Klasický střih",
                link: "/pricing",
                image: "../assets/img/placeholder/haircut.jpeg",
                description: "Klasický střih nůžkami nebo strojkem.",
            },
            {
                id: "2",
                title: "Úprava vousů",
                link: "/pricing",
                image: "../assets/img/placeholder/shave.jpeg",
                description: "Holení do hladka nebo úprava vousů břitvou.",
            },
            {
                id: "3",
                title: "Junior střih (30 min)",
                link: "/pricing",
                image: "../assets/img/placeholder/haircut_and_shave.jpeg",
                description: "Klasický střih nůžkami nebo strojkem.",
            },
            {
                id: "4",
                title: "Klasicky střih + úprava vousů",
                link: "/pricing",
                image: "../assets/img/placeholder/shave.jpeg",
                description: "Kompletní péče o vlasy a vousy.",
            },
        ],
        pricing: {
            description: "",
            image: "../assets/img/kralupy/hero.jpeg",
            items: [
                {
                    id: "1",
                    title: "",
                    items: [
                        {
                            title: "Klasický střih (30 min)",
                            price: "540 Kč",
                            description:
                                "Klasický střih nůžkami nebo strojkem.",
                        },
                        {
                            title: "Junior střih (30 min)",
                            price: "390 Kč",
                        },
                    ],
                },
                {
                    id: "2",
                    title: "",
                    items: [
                        {
                            title: "Úprava vousů / Holení vousů do hladka (30 min)",
                            price: "440 Kč",
                            description:
                                "Holení do hladka nebo úprava vousů břitvou.",
                        },
                    ],
                },
                {
                    id: "3",
                    title: "",
                    items: [
                        {
                            title: "Klasický střih + úprava vousů (60 min)",
                            price: "940 Kč",
                        },
                    ],
                },
            ],
        },
        reservationLink:
            "https://services.bookio.com/barbershop-kralupy/widget?lang=cs",
        contact: {
            email: "thecrownbarbers@gmail.com",
            phone: "+420 602 793 888",
            address: "Palackého nám. 119/15, 278 01 Kralupy nad Vltavou",
            openingHoursWeek: "9:00 - 20:00",
            openingHoursSaturday: "9:00 - 17:00",
            openingHoursSunday: "Zavřeno",
        },
        team: [
            {
                photo: "",
                name: "",
                description: "",
                position: "",
            },
        ],
        socials: {
            facebook: "",
            instagram: "",
        },
        map: {
            lat: 50.24072420718112,
            lng: 14.309881498110498,
        },
    },
    hostivice: {
        hero: {
            imageSecondary: "assets/img/placeholder/branch-placeholder.jpeg",
            title: "Hostivice",
            titlePrevious: "",
            description:
                "Svěřte své vlasy a vousy do rukou pravých profesionálů. V The Crown Barber Shop se naši barbeři postarají o to, abyste vždy odcházeli zrelaxování a hlavně spokojení. Přijďte se k nám posadit do křesla, o zbytek se postaráme my.",
        },
        aboutUs: {
            image: "../assets/img/hostivice/hero.jpeg",
            description: "",
            team: [
                {
                    photo: "../assets/img/holesovice/barberi-michal.png",
                    name: "Michal",
                    description:
                        "Michalova kariérní cesta byla poměrně přímá. Hned po vyučení v oboru kadeřník zamířil do praxe. A protože ho práce s břitvou lákala více, než dámské trvalé, pánský barbershop byl rozhodně to pravé. Po několika letech sbírání zkušeností jako zaměstnanec se rozhodl si v roce 2020 otevřít vlastní barbershop. Vítejte v Undergroundu.",
                },
                {
                    photo: "../assets/img/holesovice/barberi-ludek.png",
                    name: "Luděk",
                    description:
                        "Mám zkušenosti se stříháním 8 let. Rád dělám kompletní proměny a nejvíc mě baví hair tattoo.",
                },
                {
                    photo: "../assets/img/holesovice/barberi-lukas.png",
                    name: "Lukáš",
                    description:
                        "Mám zkušenost se stříháním 6let. Stříhání jsem dříve měl jako koníček, teď je to mou prací. Nejraději dělám klasické střihy.",
                },
            ],
        },
        services: [
            {
                id: "1",
                title: "KLASICKÝ STŘIH",
                link: "https://services.bookio.com/barbershop-hostivice/widget?lang=cs",
                description:
                    "Konzultace, střih strojkem + nůžkami, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, foukání, styling, kolínská dle výběru",
                image: "../assets/img/placeholder/haircut.jpeg",
            },
            {
                id: "2",
                title: 'ÚPRAVA VOUSŮ "HOT TOWEL"',
                link: "https://services.bookio.com/barbershop-hostivice/widget?lang=cs",
                description:
                    "Konzultace, úprava vousů, napaření vousů horkým ručníkem, holení břitvou nebo strojkem, ošetření pleti, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "3",
                title: 'KLASICKÝ STŘIH + ÚPRAVA VOUSŮ "HOT TOWEL"',
                link: "https://services.bookio.com/barbershop-hostivice/widget?lang=cs",
                description:
                    "Konzultace, klasický střih, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, foukání, styling, úprava vousů, napaření vousů horkým ručníkem, holení břitvou nebo strojkem, ošetření pleti, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/haircut_and_shave.jpeg",
            },
            {
                id: "4",
                title: "STŘIH DLOUHÝCH VLASŮ",
                link: "/pricing",
                description:
                    "Konzultace, střih nůžkami, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, foukání, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/long-hair.jpeg",
            },
            {
                id: "5",
                title: 'STŘIH DLOUHÝCH VLASŮ + ÚPRAVA VOUSŮ "HOT TOWEL"',
                link: "https://services.bookio.com/barbershop-hostivice/widget?lang=cs",
                description:
                    "Konzultace, střih nůžkami, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, foukání, úprava vousů, napaření vousů horkým ručníkem, holení břitvou nebo strojkem, ošetření pleti, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "6",
                title: "JUNIOR STŘIH",
                description: "Pro klienty ve věku od 5 do 12 let.",
                link: "https://services.bookio.com/barbershop-hostivice/widget?lang=cs",
                image: "../assets/img/placeholder/kid-haircut.png",
            },
        ],
        pricing: {
            description: "",
            image: "",
            items: [
                {
                    id: "1",
                    title: "CENÍK",
                    items: [
                        {
                            title: "Junior střih /od 5 do 12 let/",
                            price: "390 Kč",
                        },
                        {
                            title: "Klasický střih",
                            price: "590 Kč",
                        },
                        {
                            title: 'Klasický střih + úprava vousů "Hot Towel"',
                            price: "990 Kč",
                        },
                        {
                            title: 'Úprava vousů "Hot Towel"',
                            price: "440 Kč",
                        },
                        {
                            title: "Střih dlouhých vlasů",
                            price: "790 Kč",
                        },
                        {
                            title: 'Střih dlouhých vlasů + Úprava vousů "Hot Towel"',
                            price: "1190 Kč",
                        },
                    ],
                },
            ],
        },
        smallDescription: "V barbershopu je možno platit pouze hotově.",
        reservationLink:
            "https://services.bookio.com/barbershop-hostivice/widget?lang=cs",
        contact: {
            email: "thecrownbarbers@gmail.com",
            phone: "+420 606 035 757",
            address: "Čsl. armády 164, Hostivice, 253 01",
            lat: 0,
            long: 0,
            openingHoursWeek: "9:00 - 20:00",
            openingHoursSaturday: "9:00 - 17:00",
        },
        team: [
            {
                photo: "",
                name: "",
                description: "",
                position: "",
            },
        ],
        socials: {
            facebook: "",
            instagram: "",
        },
        map: {
            lat: 50.081662260466665,
            lng: 14.258217815817574,
        },
    },
    kladno: {
        hero: {
            // image: "assets/img/placeholder/branch-placeholder.jpeg",
            imageSecondary: "assets/img/placeholder/branch-placeholder.jpeg",
            title: "Kladno",
            titlePrevious: "Hairdressing and Barbers",
            description:
                "Svěřte své vlasy a vousy do rukou pravých profesionálů. V The Crown Barber Shop se naši barbeři postarají o to, abyste vždy odcházeli zrelaxování a hlavně spokojení. Přijďte se k nám posadit do křesla, o zbytek se postaráme my.",
        },
        aboutUs: {
            image: "../assets/img/kladno/hero.jpeg",
            description: "",
            smallDescription: "",
            team: [],
        },
        services: [
            {
                id: "1",
                title: "Střih strojkem",
                description:
                    "Konzultace, střih strojkem, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, kolínská dle výběru.",
                image: "../assets/img/placeholder/haircut.jpeg",
            },
            {
                id: "2",
                title: "Klasický střih",
                description:
                    "Konzultace, střih strojkem, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, foukání, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "3",
                title: 'Úprava vousů "Hot Towel"',
                description:
                    "Konzultace, úprava vousů, napaření vousů horkým ručníkem, holení břitvou nebo strojkem, ošetření pleti, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/haircut_and_shave.jpeg",
            },
            {
                id: "4",
                title: 'Střih strojkem + Úprava vousů "Hot Towel"',
                description:
                    "Konzultace, střih strojkem, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, úprava vousů, napaření vousů horkým ručníkem, holení břitvou nebo strojkem, ošetření pleti, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "5",
                title: 'Klasický střih + Úprava vousů "Hot Towel"',
                description:
                    "Konzultace, klasický střih, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, foukání, styling, úprava vousů, napaření vousů horkým ručníkem, holení břitvou nebo strojkem, ošetření pleti, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/haircut.jpeg",
            },
            {
                id: "6",
                title: "Střih dlouhých vlasů",
                description:
                    "Konzultace, střih nůžkami, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, foukání, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "7",
                title: "Střih dlouhých vlasů",
                description:
                    "Konzultace, střih nůžkami, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, foukání, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "8",
                title: 'Střih dlouhých vlasů + Úprava vousů "Hot Towel"',
                description:
                    "Konzultace, střih nůžkami, úprava obočí, zaholení kontur, opálení ušních chloupků, mytí a masáž hlavy, úprava vousů, napaření vousů horkým ručníkem, holení břitvou nebo strojkem, ošetření pleti, styling, kolínská dle výběru.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "9",
                title: "Junior střih",
                description: "Pro klienty ve věku od 5 do 12 let.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "10",
                title: "Doplňkové služby",
                description:
                    "Černá maska na vytažení pórů, Dobarvování vousů, Hair tattoo/motiv dle přání zákazníka",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "11",
                title: "Kariéra",
                description:
                    "Koho hledáme: Spolehlivého člověka s pracovní morálkou, který má zájem o práci, s citem pro detail a nebojí se komunikovat se zákazníky.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
            {
                id: "12",
                title: "Přijedeme na Vaší akci",
                description:
                    "Pořádáte oslavu, svatbu, konferenci nebo chcete přilákat návštěvníky na slavnosti? Pozvěte The Crown Barber Shop a my za vámi přijedeme i s naším křeslem. Na místě se náš barber postará o to, že si všichni zájemci odnesou nevšední zážitek a k tomu perfektní sestřih.",
                image: "../assets/img/placeholder/shave.jpeg",
            },
        ],
        pricing: {
            description: "",
            image: "",
            items: [
                {
                    id: "1",
                    title: "",
                    items: [
                        {
                            title: "Střih strojkem",
                            price: "440,- / 30 min",
                        },
                        {
                            title: "Klasický střih",
                            price: "540,- / 30 min",
                        },
                        {
                            title: 'Úprava vousů "Hot Towel"',
                            price: "440,- / 30 min",
                        },
                        {
                            title: "Střih dlouhých vlasů",
                            price: "890,- / 60 min",
                        },
                        {
                            title: 'Střih dlouhých vlasů + úprava vousů "Hot Towel"',
                            price: "1240,- / 90 min",
                        },
                        {
                            title: 'Střih strojkem + úprava vousů "Hot Towel"',
                            price: "840,- / 60 min",
                        },
                        {
                            title: 'Klasický střih + úprava vousů "Hot Towel"',
                            price: "940,- / 60 min",
                        },
                        {
                            title: "Junior střih /od 5 do 12 let/",
                            price: "390,- / 30 min",
                        },
                    ],
                },
            ],
        },
        smallDescription: "Přijímáme pouze platby v hotovosti.",
        reservationLink:
            "https://services.bookio.com/the-crown-barber-shop/widget?lang=cs",
        contact: {
            email: "thecrownbarbers@gmail.com",
            phone: "+420 722 836 799 ",
            address: "T. G. Masaryka 106, 272 01 Kladno",
            lat: 0,
            long: 0,
            openingHoursWeek: "9:00 - 20:00",
            openingHoursSaturday: "9:00 - 17:00",
        },
        team: [
            {
                photo: "",
                name: "",
                description: "",
                position: "",
            },
        ],
        socials: {
            facebook: "",
            instagram: "",
        },
        map: {
            lat: 50.146194,
            lng: 14.101981,
        },
    },
};
