import React from "react";
import useBranchData from "../../useBranchData";

const ContactsUs = () => {
    const { branch } = useBranchData();

    return (
        <section id='contact-us' className='block'>
            <div className='bg-primary text-tertiary spacer p-top-xl p-bottom-xl'>
                <div className='wrapper text-center'>
                    <div className='title'>
                        <h2 className='text-uppercase'>Kontaktujte nás</h2>
                    </div>

                    <a
                        href={`${process.env.PUBLIC_URL}/${branch}/contacts`}
                        className='btn btn-outline-tertiary text-uppercase'
                    >
                        Kontakt
                    </a>
                </div>
            </div>
        </section>
    );
};

export default ContactsUs;
