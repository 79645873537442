import React from 'react';

const NewsTitle = () => {
    return (
        <div className="title">
            <h2 className="text-uppercase">5 Benefits of going to a BARBER</h2>
        </div>
    );
};

export default NewsTitle;
