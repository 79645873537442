import React from "react";
import HeaderLogo from "../../blocks/logo/HeaderLogo";
import Actions from "../actions/Actions";
import BranchModal from "../../components/modal/BranchModal";

const Header = ({ logoColor, isLandingPage, isHomePage, isBgDark }) => {
    return (
        <header
            id='header'
            className={`site-header ${isBgDark && "bg-dark"} ${
                isLandingPage && "bg-img"
            }`}
            style={isHomePage && { position: "absolute" }}
        >
            <div className='wrapper'>
                <div className='header-content d-flex justify-content-between'>
                    <div className='header-left align-self-center'>
                        <BranchModal isBgDark />
                        {/* <div className='links'>
                            <div className='links-items'>
                                <div className='links-item'>
                                    <a
                                        href={process.env.PUBLIC_URL + "/"}
                                        className='btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto'
                                    >
                                        Domů
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {!isLandingPage && (
                        <div className='header-center align-self-center'>
                            <HeaderLogo logoColor={logoColor} />
                        </div>
                    )}

                    <div className='header-right d-flex justify-content-end'>
                        {/* <div className='d-flex align-items-center'>
                            {!isLandingPage && <MenuModal />}
                        </div> */}

                        {!isLandingPage && <Actions />}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
