import React from "react";
import useBranchData from "../../useBranchData";

const AboutOurTeam = () => {
    const {
        branchData: {
            aboutUs: { team },
        },
    } = useBranchData();

    return (
        <div id='our-team' className='block spacer p-top-xl'>
            {team.length !== 0 && (
                <div className='title'>
                    <h2 className='hr'>Náš tým</h2>
                </div>
            )}

            {team.length !== 0 && (
                <div className='row gutter-width-sm with-pb-md'>
                    {team.map(
                        (barber) =>
                            barber.photo && (
                                <div
                                    className='col-sm-6 col-md-6 col-lg-4 col-xl-4'
                                    key={barber.name}
                                >
                                    <div className='team-member'>
                                        <div className='img object-fit'>
                                            <div className='object-fit-cover'>
                                                <img
                                                    src={barber.photo}
                                                    alt={barber.name}
                                                />
                                            </div>
                                        </div>

                                        <div className='team-member-content'>
                                            {barber.position && (
                                                <div className='team-member-position'>
                                                    <p>{barber.position}</p>
                                                </div>
                                            )}

                                            <h4 className='team-member-t-head'>
                                                {barber.name}
                                            </h4>

                                            {barber.description && (
                                                <div className='team-member-description'>
                                                    <p>{barber.description}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                    )}

                    {/* OLD BARBER EXAMPLE WITH SOCIALS<div className='col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                    <div className='team-member'>
                        <div className='img object-fit'>
                            <div className='object-fit-cover'>
                                <img
                                    src='assets/img/placeholder/507x600.jpg'
                                    alt='Andrew Nick'
                                />
                            </div>
                        </div>

                        <div className='team-member-content'>
                            <div className='team-member-position'>
                                <p>Beard Stylist</p>
                            </div>

                            <h4 className='team-member-t-head'>Andrew Nick</h4>

                            <div className='team-member-description'>
                                <p>
                                    Contrary to popular belief, Lorem Ipsum is
                                    not simply random text. It has roots.
                                </p>
                            </div>
                        </div>

                        <nav className='team-member-nav-items'>
                            <ul className='nav'>
                                <li className='nav-item'>
                                    <a
                                        title='Facebook'
                                        href='https://www.facebook.com/adveits/'
                                    >
                                        <i className='fab fa-facebook-f'></i>
                                    </a>
                                </li>

                                <li className='nav-item'>
                                    <a
                                        title='LinkedIn'
                                        href='https://www.linkedin.com/company/18135051/'
                                    >
                                        <i className='fab fa-linkedin-in'></i>
                                    </a>
                                </li>

                                <li className='nav-item'>
                                    <a
                                        title='Instagram'
                                        href='https://www.instagram.com/adveits/'
                                    >
                                        <i className='fab fa-instagram'></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className='col-sm-6 col-md-6 col-lg-4 col-xl-4'>
                    <div className='team-member'>
                        <div className='img object-fit'>
                            <div className='object-fit-cover'>
                                <img
                                    src='assets/img/placeholder/507x600.jpg'
                                    alt='Tom Henders'
                                />
                            </div>
                        </div>

                        <div className='team-member-content'>
                            <div className='team-member-position'>
                                <p>Hair Stylist</p>
                            </div>

                            <h4 className='team-member-t-head'>Tom Henders</h4>

                            <div className='team-member-description'>
                                <p>
                                    Contrary to popular belief, Lorem Ipsum is
                                    not simply random text. It has roots.
                                </p>
                            </div>
                        </div>

                        <nav className='team-member-nav-items'>
                            <ul className='nav'>
                                <li className='nav-item'>
                                    <a
                                        title='Facebook'
                                        href='https://www.facebook.com/adveits/'
                                    >
                                        <i className='fab fa-facebook-f'></i>
                                    </a>
                                </li>

                                <li className='nav-item'>
                                    <a
                                        title='LinkedIn'
                                        href='https://www.linkedin.com/company/18135051/'
                                    >
                                        <i className='fab fa-linkedin-in'></i>
                                    </a>
                                </li>

                                <li className='nav-item'>
                                    <a
                                        title='Instagram'
                                        href='https://www.instagram.com/adveits/'
                                    >
                                        <i className='fab fa-instagram'></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> */}
                </div>
            )}
        </div>
    );
};

export default AboutOurTeam;
