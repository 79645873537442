import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import HeaderLogo from "../../blocks/logo/HeaderLogo";
import BranchModalMenu from "../../blocks/menu/BranchModalMenu";

function BranchModal({ isBgDark }) {
    const [showModal, setShowModal] = useState(false);

    const open = () => {
        setShowModal(true);
    };

    const close = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className='menu-toggle'>
                <button
                    onClick={open}
                    type='button'
                    className={`btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto text-uppercase ${
                        isBgDark ? "text-white" : ""
                    }`}
                    data-toggle='modal'
                    data-target='#menu-modal'
                >
                    Zvolte pobočku
                </button>
            </div>

            <Modal
                className={`menu-modal`}
                show={showModal}
                onHide={close}
                backdrop={false}
                aria-labelledby='menu-modal'
                aria-hidden='true'
                dialogClassName='modal-full'
            >
                <div className='wrapper'>
                    <div className='modal-content'>
                        <div className='modal-header modal-header-top'>
                            <div className='header-content d-flex justify-content-between w-100'>
                                <div className='header-left align-self-center'></div>

                                <div className='header-center align-self-center'>
                                    <HeaderLogo logoColor={"light"} />
                                </div>

                                <div className='header-right d-flex justify-content-end'>
                                    <div className='menu-close-toggle'>
                                        <button
                                            onClick={close}
                                            type='button'
                                            className='btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto'
                                            data-dismiss='modal'
                                            aria-label='Close'
                                        >
                                            Zavřít
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='modal-body modal-body-centered menu-primary-show-1-nav-item menu-secondary-show-1-nav-item'>
                            <div className='animated zoomin w-100'>
                                <BranchModalMenu closeModal={close} />

                                <div className='modal-text'>
                                    <p>
                                        © {new Date().getFullYear()} The Crown
                                        Barber Shop
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default BranchModal;
