import React from "react";
import useBranchData from "../../useBranchData";

const AboutsUs = () => {
    const {
        branchData: {
            aboutUs: { description, smallDescription },
        },
    } = useBranchData();
    console.log(description && 1, 2);

    return (
        description && (
            <section id='about-us' className='block spacer p-top-xl pb-100'>
                <div className='wrapper'>
                    <div className='row gutter-width-sm'>
                        <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12'>
                            <div className='title'>
                                <h2 className='text-uppercase'>
                                    {description}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className='row gutter-width-sm justify-content-end'>
                        <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12'>
                            <div className='description'>
                                <p>{smallDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    );
};

export default AboutsUs;
