import React from "react";
import useBranchData from "../../useBranchData";

const AboutImg = () => {
    const {
        branchData: {
            hero: { title },
            aboutUs: { image },
        },
    } = useBranchData();

    return (
        image && (
            <div id='about-us-img' className='block'>
                <div className='about-img'>
                    <div className='img object-fit'>
                        <div className='object-fit-cover'>
                            <img src={image} alt={title} />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default AboutImg;
