import React from "react";
import scriptLoader from "react-async-script-loader";
import GoogleMapsStyle from "../../data/google-maps/googleMapsStyle";

class Maps extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.map = null;
    }

    componentDidUpdate(prevProps) {
        const { isScriptLoaded } = this.props;

        if (isScriptLoaded && !prevProps.isScriptLoaded) {
            this.initMap();
        }
    }

    initMap() {
        const { lat, lng, isScriptLoadSucceed, onError } = this.props;

        if (isNaN(lat) || isNaN(lng)) {
            console.error("Invalid coordinates for lat or lng");
            return;
        }

        const address_latlng = new window.google.maps.LatLng(lat, lng);

        if (isScriptLoadSucceed) {
            this.map = new window.google.maps.Map(this.mapRef.current, {
                center: address_latlng,
                zoom: 18,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                styles: GoogleMapsStyle,
            });

            const icon = {
                url: "../../assets/img/demo/16_img.png",
                size: new window.google.maps.Size(112, 118),
            };

            this.map.setCenter(address_latlng);

            new window.google.maps.Marker({
                position: address_latlng,
                map: this.map,
                icon: icon,
            });
        } else {
            onError();
        }
    }

    render() {
        return <div id='google-maps' ref={this.mapRef}></div>;
    }
}

Maps.defaultProps = {
    lat: 50.10323021048523,
    lng: 14.449433300000003,
};

export default scriptLoader([
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyBQ3Wb9OO16bZQNbsyRKzksqhm-KVDWKzg",
])(Maps);
