import React from "react";

const PageTitleNewsSingle = () => {
    return (
        <div id='page-title'>
            <div className='wrapper text-center'>
                <h1 className='large text-uppercase'>Novinky</h1>
            </div>
        </div>
    );
};

export default PageTitleNewsSingle;
