import { useParams } from "react-router-dom";
import { DefaultBranch, DETAILS } from "./data/branches/items";

const useBranchData = () => {
    const { branch } = useParams();
    const branchData = DETAILS[branch] || DefaultBranch;

    return { branch, branchData };
};

export default useBranchData;
