import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleHome from "../blocks/page-title/PageTitleHome";
import ContactsUs from "../blocks/contacts/ContactUs";
import AboutsUs from "../blocks/about/AboutUs";
import OpeningHours from "../blocks/opening-hours/OpeningHours";
import { DETAILS } from "../data/branches/items";
import useBranchData from "../useBranchData";
import ContactsInside from "../blocks/contacts/Contacts";

const Home = () => {
    document.body.classList.add("home");
    document.body.classList.add("header-absolute-true");
    const {
        branch,
        branchData: {
            pricing: { items },
        },
    } = useBranchData();

    return (
        <Fragment>
            <MetaTags>
                <meta charSet='UTF-8' />
                <title>The Crown Barbershop</title>

                <meta httpEquiv='x-ua-compatible' content='ie=edge' />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1'
                />
                <meta name='description' content='' />
                <meta name='keywords' content='' />
                <meta name='robots' content='index, follow, noodp' />
                <meta name='googlebot' content='index, follow' />
                <meta name='google' content='notranslate' />
                <meta name='format-detection' content='telephone=no' />
            </MetaTags>

            <Loading />

            <Header logoColor='light' isHomePage />

            <main id='main' className='site-main content-no-spacing'>
                <div className='content'>
                    <div className='clearfix'>
                        <PageTitleHome />
                        <a
                            className='reservation'
                            href={DETAILS[branch]?.reservationLink || ""}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <span>Rezervovat termín</span>
                        </a>
                        <AboutsUs />
                        <ContactsUs />
                        {/* <ServicesHome /> */}
                        <div id='page-content' className='spacer p-top-xl'>
                            <div className='wrapper'>
                                <div className='content'>
                                    <div className='clearfix'>
                                        <div
                                            id='price-list'
                                            className='block spacer p-top-xl'
                                        >
                                            <div className='title'>
                                                <h2 className='hr text-uppercase'>
                                                    Naše služby
                                                </h2>
                                            </div>
                                            <div className='price-list'>
                                                <div className='price-list-items'>
                                                    {items.map((item) => (
                                                        <div className='price-list-item'>
                                                            <div className='price-list-item-title'>
                                                                <h3>
                                                                    {item.title}
                                                                </h3>
                                                            </div>

                                                            <ul className='price-list-item-list-group list-group'>
                                                                {item.items.map(
                                                                    (
                                                                        serviceItem
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                serviceItem.id
                                                                            }
                                                                            className='list-group-item d-flex justify-content-between align-items-center'
                                                                        >
                                                                            <span className='list-group-title'>
                                                                                {
                                                                                    serviceItem.title
                                                                                }
                                                                            </span>
                                                                            <span className='list-group-price'>
                                                                                {
                                                                                    serviceItem.price
                                                                                }
                                                                            </span>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <AboutOurTeam /> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <OpeningHours />
                        <div className='spacer p-top-xl pb-100'>
                            <div className='wrapper'>
                                <div className='content'>
                                    <div className='clearfix'>
                                        <div className='row gutter-width-sm with-pb-xl spacer p-top-lg'>
                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                                <div className='title pb-50'>
                                                    <h2 className='text-uppercase'>
                                                        Napište nám
                                                    </h2>
                                                </div>
                                                <ContactsInside />
                                            </div>

                                            {branch && (
                                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                                    <div className='title pb-50'>
                                                        <h2 className='text-uppercase'>
                                                            Pohled na holičství
                                                            z ulice
                                                        </h2>
                                                    </div>
                                                    <div className='contact-image'>
                                                        <img
                                                            src={`../assets/img/${branch}/outside.jpeg`}
                                                            alt='Pobočka zvenčí'
                                                        />
                                                    </div>

                                                    {/* <div className='contact-form-shortcode'>
                                                <ContactForm />
                                            </div> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div id='img' className='block spacer p-top-xl'>
                            <div className='img-no-wrap-1 img-gray'>
                                <div className='img object-fit'>
                                    <div className='object-fit-cover'>
                                        <img src={imageSecondary} alt={title} />
                                    </div>
                                </div>
                            </div>
                        </div>

                         <News />

                        <Newsletter /> */}
                        <br />
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Home;
